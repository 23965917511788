<template>
    <div class="footer">
        <!-- <h1>底部</h1> -->
        <div class="f-box">
            <!-- 公司信息 -->
            <div class="box-info">
                <div class="info-item" ref="itemP1">
                    <div class="item-t">客服热线</div>
                    <img class="item-img" src="../assets/image/icon_phone.png" alt="">
                    <div class="item-p" >17850832692</div>
                    <div class="item-z">工作时间：9:30~18:30</div>
                </div>

                <div class="info-item" ref="itemP2">
                    <div class="item-t">公司地址</div>
                    <img class="item-img" src="../assets/image/icon_address.png" alt="">
                    <div class="item-p" >福州国家高新区软件园</div>
                    <div class="item-z">福建省-福州市-闽侯</div>
                </div>

                <div class="info-item" ref="itemP3">
                    <div class="item-z" style="margin-bottom: 20px;" >
                        友情链接
                    </div>
                    <div class="item-a" @click="goTo('purchase')" >
                        产品购买
                    </div>

                    <div class="item-a" @click="goTo('extract')" >
                        获取api
                    </div>

                    <div class="item-a" @click="goTo('tutorials')" >
                        使用教程
                    </div>

                    <!-- <div class="item-a" @click="goLj()" >
                        全网最大的免费产品搬砖论坛-老玖圈
                    </div> -->
                </div>

                <div class="info-code">
                    <div class="code-t">关于我们</div>
                    <div class="code-box">
                        <div class="code-item">
                            <img class="code-item-img" src="../assets/image/xhs.png" alt=""
                                style="width: 60px;height: 60px;" ref="img1">
                            <div class="code-item-title">小红书</div>
                        </div>



                        <div class="code-item" style="margin: 0 40px;">
                            <img class="code-item-img" src="../assets/image/dy.png" alt=""
                                style="width: 60px;height: 60px;" ref="img2">
                            <div class="code-item-title">抖音</div>
                        </div>

                        <div class="code-item">
                            <img class="code-item-img" src="../assets/image/blbl.png" alt=""
                                style="width: 100px;height: 50px;" ref="img3">
                            <div class="code-item-title">哔哩哔哩</div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- 相关声明 -->
            <div class="box-statement">
                <div class="statement-l">
                    <div>
                        <p>免责声明：</p>
                        <p>亿品云仅提供服务，用户使用亿品云服务从事的任何行为，均不代表亿品云服务的意志和观点且与亿品云立场无关；</p>
                        <P>严禁用户使用，亿品云服务从事任何违法犯罪行为，由此产生相关责任由当事用户自负，亿品云服务不承担任何法律责任。</P>
                    </div>
                    <p style="margin-top: 34px;">Copyright © 卓鑫网络科技有限公司版权所有</p>
                    <div>

                    </div>
                </div>
                <div class="statement-r">
                    <div>
                        <a href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备2023021192号-1</a>
                        <p>增值电信业务许可证：闽B2-20240141</p>
                        <p>互联网虚拟专用许可证：B1-20240107</p>
                        <p style="margin-bottom: 12px;">EDI在线数据处理与交易处理业务许可证：闽B2-20240141</p>
                    </div>
                    <div>
                        <img style="height: 20px;float: left;" src="../assets/image/gab-icon.png" alt="">
                        <a style="margin-left: 5px;"
                            href="https://beian.mps.gov.cn/#/query/websearch?code=35018302000131">闽公网安备35018302800131</a>
                    </div>
                </div>
            </div>
        </div>

        <Login ref="login"></Login>

    </div>
</template>

<script>
// @ is an alias to /src

import Login from '@/components/Login.vue'
export default {
    name: '',
    components: {
        Login
    },
    data(){
        return{
            visibleItems: [] // 用于追踪已进入视口的元素: [] 
        }
    },
    mounted() {
        this.observer = new IntersectionObserver(this.handleIntersect, {
            threshold: 0.1 // 定义当元素 10% 可见时触发
        });

        // 确保所有观察的元素都已存在
        if (this.$refs.itemP1) this.observer.observe(this.$refs.itemP1);
        if (this.$refs.itemP2) this.observer.observe(this.$refs.itemP2);
        if (this.$refs.itemP3) this.observer.observe(this.$refs.itemP3);
        if (this.$refs.itemP4) this.observer.observe(this.$refs.itemP4);
        if (this.$refs.itemP5) this.observer.observe(this.$refs.itemP5);
        if (this.$refs.img1) this.observer.observe(this.$refs.img1);
        if (this.$refs.img2) this.observer.observe(this.$refs.img2);
        if (this.$refs.img3) this.observer.observe(this.$refs.img3);
    },
    beforeDestroy() {
        this.observer.disconnect(); // 清除观察器以避免内存泄漏
    },
    methods: {
        goTo(path) {
            this.$router.push(path)
        },
        goLj() {
            window.location.href = 'http://www.laojiuyx.xyz/';
        },
        goPersonal() {
            if (localStorage.getItem('token')) {
                this.$router.push('personal')
            } else {
                this.$refs.login.loginBox1 = true
            }
        },

        handleIntersect(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.target === this.$refs.itemP1 && !this.visibleItems.includes('itemP1')) {
                        entry.target.classList.add('slide-in-left'); // 添加动画类
                        this.visibleItems.push('itemP1');
                    } else if (entry.target === this.$refs.itemP2 && !this.visibleItems.includes('itemP2')) {
                        entry.target.classList.add('slide-in-bottom'); // 其它动画或者调整名字
                        this.visibleItems.push('itemP2');
                    }else if (entry.target === this.$refs.itemP3 && !this.visibleItems.includes('itemP3')) {
                        entry.target.classList.add('slide-in-top'); // 其它动画或者调整名字
                        this.visibleItems.push('itemP3');
                    }else if (entry.target === this.$refs.itemP4 && !this.visibleItems.includes('itemP4')) {
                        entry.target.classList.add('shake-top'); // 其它动画或者调整名字
                        this.visibleItems.push('itemP4');
                    }else if (entry.target === this.$refs.itemP5 && !this.visibleItems.includes('itemP5')) {
                        entry.target.classList.add('shake-bottom'); // 其它动画或者调整名字
                        this.visibleItems.push('itemP5');
                    }else if (entry.target === this.$refs.img1 && !this.visibleItems.includes('img1')) {
                        entry.target.classList.add('roll-in-left'); // 其它动画或者调整名字
                        this.visibleItems.push('img1');
                    }else if (entry.target === this.$refs.img2 && !this.visibleItems.includes('img2')) {
                        entry.target.classList.add('bounce-top'); // 其它动画或者调整名字
                        this.visibleItems.push('img2');
                    }else if (entry.target === this.$refs.img3 && !this.visibleItems.includes('img3')) {
                        entry.target.classList.add('bounce-in-right'); // 其它动画或者调整名字
                        this.visibleItems.push('img3');
                    }
                    
                    this.observer.unobserve(entry.target); // 不再观察已经动画过的元素
                }
            });
        },


    }
}
</script>



<style lang="less" scoped>
.footer {
    width: 100%;
    height: 386px;
    background: #162D4C;
    padding-top: 20px;
    /* // position: fixed;
    // bottom: 0; */
}

.f-box {
    max-width: 1200px;
    min-height: 330px;
    // background: salmon;
    margin: 0 auto;
}

.box-info {
    // background: seagreen;
    padding-bottom: 38px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
}

.info-item {
    width: 260px;
    height: 142px;
    // background: skyblue;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-right: 20px;

    .item-img {
        width: 26px;
        // background: linear-gradient(90deg, #3650e4 0%, #A5D7D4 32%, #80CCCB 50%, #6687e0 75%, #33B0CB 98%);
        // background: linear-gradient(2deg, #6430F3 0%, #4C6FF0 51%, #FF4EAA 98%);
        border-radius: 16px 16px 16px 16px;
        margin: 20px 0 16px 0;
    }

    .item-t {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.75);
        line-height: 22px;
    }

    .item-p {
        margin-bottom: 6px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 24px;
    }

    .item-z {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        line-height: 22px;

    }

    .item-a {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        margin-bottom: 12px;
        cursor: pointer;
    }
}

.info-code {
    width: 400px;
    height: 152px;
    // background: slateblue;
    margin-left: 12px;

    .code-box {
        display: flex;
        margin-top: 16px;
        align-items: end;
    }

    .code-t {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.75);
        line-height: 22px;
    }

    .code-item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .code-item-img {
        width: 88px;
        height: 88px;
    }

    .code-item-title {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
        margin-top: 9px;
    }
}

.box-statement {

    margin-top: 28px;
    display: flex;


    .statement-r {
        margin-left: 62px;
        // background: salmon;
    }

    a {
        text-decoration: none;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        line-height: 22px;
    }

    p {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        line-height: 22px;
    }
}


// 从左往右滑动
.slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

// 从右往左滑动
.slide-in-right {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

// 小标题
.rotate-vert-center {
    -webkit-animation: rotate-vert-center 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: rotate-vert-center 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}


@-webkit-keyframes rotate-vert-center {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }

    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@keyframes rotate-vert-center {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }

    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}


.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2024-10-25 16:57:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.shake-horizontal {
	-webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-10-25 16:58:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}

.shake-bottom {
	-webkit-animation: shake-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: shake-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-10-25 17:1:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

.shake-top {
	-webkit-animation: shake-top 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: shake-top 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-10-25 17:2:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-top
 * ----------------------------------------
 */
 @-webkit-keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

.roll-in-left {
	-webkit-animation: roll-in-left 0.6s ease-out both;
	        animation: roll-in-left 0.6s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-10-25 17:9:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
            transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}
@keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
            transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

.roll-in-top {
	-webkit-animation: roll-in-top 0.8s ease-out both;
	        animation: roll-in-top 0.8s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-10-25 17:11:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes roll-in-top {
  0% {
    -webkit-transform: translateY(-800px) rotate(-540deg);
            transform: translateY(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}
@keyframes roll-in-top {
  0% {
    -webkit-transform: translateY(-800px) rotate(-540deg);
            transform: translateY(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}

.bounce-top {
	-webkit-animation: bounce-top 1s ;
	        animation: bounce-top 1s ;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-10-25 17:15:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}

.bounce-in-right {
	-webkit-animation: bounce-in-right 1.1s both;
	        animation: bounce-in-right 1.1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-10-25 17:16:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
            transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
            transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
            transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
            transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-10-25 17:21:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

</style>